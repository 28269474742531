import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charts-report',
  templateUrl: './charts-report.component.html',
  styleUrls: ['./charts-report.component.css']
})
export class ChartsReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
