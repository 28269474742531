

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UrlSanitizerPipe } from '../../../../../pipes/url-sanitizer.pipe';



import { REPORTS, KPI } from 'src/app/data/mock-report';
import { Report } from 'src/app/model/report';
@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css']

})
export class MonitoringComponent implements OnInit {
reports = KPI;
selectedReport: Report;
  constructor(private router: Router) { }

  ngOnInit() {
  }
 onSelect(report: Report){
   this.selectedReport = report;
 }
}
