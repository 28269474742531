import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class DRFAuthenticationInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({ headers: req.headers.set('Authorization', `Token ${localStorage.getItem('app-token')}` ) });
    return next.handle(clonedRequest);
  }
}
