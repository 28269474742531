import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  postFile(fileToUpload: File): Observable<any> {
    const endpoint = '192.168.1.2:56411/api/file';
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload);
    return this.http.post(endpoint, formData)
      

}

handleError(error) {
  console.log(error)
}
}
