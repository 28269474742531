import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UrlSanitizerPipe } from '../../../../pipes/url-sanitizer.pipe';
import { REPORTS } from '../../../../data/mock-report';
import { Report } from '../../../../model/report';

@Component({
  selector: 'app-report-dashboard-a6',
  templateUrl: './report-dashboard-a6.component.html',
  styleUrls: ['./report-dashboard-a6.component.css']
})
export class ReportDashboardA6Component implements OnInit {
  reports = REPORTS;
  selectedReport: Report;

  constructor() { }

  ngOnInit() {
  }

  onSelect(report: Report){
    this.selectedReport = report;
  }

}




