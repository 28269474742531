import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-blocking-modal',
  templateUrl: './blocking-modal.component.html',
  styleUrls: ['./blocking-modal.component.css']
})
export class BlockingModalComponent {

  constructor(
    public dialogRef: MatDialogRef<BlockingModalComponent>) {}

}
