import { MatButtonModule, MatCheckboxModule, MatIconModule, MatProgressSpinnerModule, 
         MatDividerModule, MatInputModule, MatGridListModule, MatSnackBarModule, MatTabsModule} from '@angular/material';
import { NgModule } from '@angular/core';
import { MatCardModule} from '@angular/material/card';
import { MatSelectModule} from '@angular/material/select';
import { MatTableModule} from '@angular/material/table';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { MatDialogModule} from '@angular/material/dialog';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

@NgModule({
  imports: [MatButtonModule, MatCheckboxModule, 
    MatCardModule, MatSelectModule, MatTableModule,
    MatIconModule, MatProgressBarModule, BrowserModule, BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule, MatProgressSpinnerModule,
    AppRoutingModule, MatInputModule, MatGridListModule, MatCardModule, MatDividerModule,
    MatSelectModule, MatSnackBarModule, MatDialogModule, MatTabsModule, MatButtonToggleModule],

  exports: [MatButtonModule, MatCheckboxModule, 
    MatCardModule, MatSelectModule, MatTableModule,
    MatIconModule, MatProgressBarModule,BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, MatProgressSpinnerModule,
    AppRoutingModule, MatInputModule, MatGridListModule, MatCardModule, MatDividerModule,
    MatSelectModule, MatSnackBarModule, MatDialogModule, MatTabsModule, MatButtonToggleModule],
})
export class MaterialModule { }
