import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { MeasurementChange } from '../types/MeasurementChange';

export interface EntryBundle {
  measures: { string: Array<{ any }> },
  coordinates: { string: Array<{ string: { x: number, y: number } }> },
  coarse_coordinates: { string: Array<{ x: number, y: number }> },
  scale_data: { string: { xMin: number, xMax: number, yMin: number, yMax: number } }
}

@Injectable({
  providedIn: 'root'
})
export class ProgressEntryServiceService {
  private baseUrl = environment.baseUrl;

  private getDataUrl = '/entries/all_entries/';
  private entryGroupParamMap = '?entry_group='

  private getEntryGroupsUrl = '/entries/all_entry_groups/';

  private postDataUrl = '/entries/bulk_update/';

  private processProgress = '/entries/process_entry_data/';

  private deletePriorEntriesUrl = '/entries/delete_prior_project_week/' 

  constructor(private http: HttpClient) { }

  deletePriorEntries(): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.deletePriorEntriesUrl, null);
  }

  postEntries(entries: Array<MeasurementChange>): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.postDataUrl, entries);
  }

  getAllEntries(): Observable<EntryBundle> {
    return this.http.get<EntryBundle>(this.baseUrl + this.getDataUrl);
  }

  // After post navigation gets screwed
  processtEntries(project_week: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.processProgress, null);
  }

  getCurrentEntries(entry_group_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + this.getDataUrl + this.entryGroupParamMap + entry_group_id);
  }

  getEntryGroups(): Observable<any> {
    return this.http.get<any>(this.baseUrl + this.getEntryGroupsUrl);
  }
}
