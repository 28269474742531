import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || 'false')

  private baseUrl = environment.baseUrl;
  private loginUrl = '/login/';
  constructor(private http: HttpClient) { }

  setLoggedIn(value: boolean){
    this.loggedInStatus = value
    localStorage.setItem('loggedIn', 'true')
  }

  get isLoggedIn(){
    return JSON.parse(localStorage.getItem('loggedIn') || this.loggedInStatus.toString())
  }

  login(username: string, password: string): Observable<any> {

    const body = { 'username': username, 'password': password };
    //const httpOptions = {
    //  headers: new HttpHeaders({
    //    'Content-Type': 'application/json',
    //  })
    //};
    //httpOptions.headers.set("X-CSRFToken", this.cookieService.get('csrftoken'));

    return this.http.post(this.baseUrl+this.loginUrl, body); //{ headers: { "X-CSRFToken": this.cookieService.get('csrftoken')}
  }
}

