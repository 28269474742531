import { Component, OnInit, Inject} from '@angular/core';

import {MatDialog, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-project-dashboard-a6',
  templateUrl: './project-dashboard-a6.component.html',
  styleUrls: ['./project-dashboard-a6.component.css']
})
export class ProjectDashboardA6Component {

  constructor(public dialog: MatDialog) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(importFileDialog, {
      width: '350px'
    })
  }

}

@Component({
  selector: 'import-file-dialog',
  templateUrl: 'import-file-dialog.html',
})
export class importFileDialog{

  constructor(
    public dialogRef: MatDialogRef<importFileDialog>
  ){}

  onNoClick(): void {
    this.dialogRef.close();
  }

}