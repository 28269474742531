import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-dashboard-a6-pm',
  templateUrl: './project-dashboard-a6-pm.component.html',
  styleUrls: ['./project-dashboard-a6-pm.component.css']
})
export class ProjectDashboardA6PmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
