
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UrlSanitizerPipe } from '../../../../../pipes/url-sanitizer.pipe';



import { REPORTS, KPI, WARNINGS } from 'src/app/data/mock-report';
import { Report } from 'src/app/model/report';
@Component({
  selector: 'app-warnings-report',
  templateUrl: './warnings-report.component.html',
  styleUrls: ['./warnings-report.component.css']

})
export class WarningsReportComponent implements OnInit {
reports = WARNINGS;
selectedReport: Report;
  constructor(private router: Router) { }

  ngOnInit() {
  }
 onSelect(report: Report){
   this.selectedReport = report;
 }
}
