import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PROJECTS } from '../../data/mock-projects';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  projects = PROJECTS;
  constructor(private router: Router) { }

  navigateTo(value){

    this.router.navigate(['./projectDashboard',value]);

  }
  ngOnInit() {
  }

}
