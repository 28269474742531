import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UrlSanitizerPipe } from '../../../../pipes/url-sanitizer.pipe';
import { Report } from '../../../../model/report';
import { REPORTS } from '../../../../data/mock-report';
@Component({
  selector: 'app-ssrs-report',
  templateUrl: './ssrs-report.component.html',
  styleUrls: ['./ssrs-report.component.css'],

})
export class SsrsReportComponent implements OnInit {
reports = REPORTS;
selectedReport: Report;
  constructor(private router: Router) { }

  ngOnInit() {
  }
 onSelect(report: Report){
   this.selectedReport = report;
 }
}
