import { Report } from "../model/report";


export const REPORTS: Report[] = [
  { id: 1, name: 'Evolution', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/Evolution?rs:embed=true' },
  { id: 2, name: 'Monitoring All', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/Monitoring_All?rs:embed=true' },
  { id: 3, name: 'Eplass Progress', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/MonitoringAll_EplassProgress?rs:embed=true' },
  { id: 4, name: 'Delay Ongoing', url:'https://www.httechus.com/ReportServer/Pages/ReportViewer.aspx?%2fEplass+Prototype+Reports%2fDelayOngoingDoc&rs:Command=Render?rs:embed=true' },
  { id: 5, name: 'Weekly Progress', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/WeeklyProgress?rs:embed=true' },
  { id: 6, name: 'Stop Drawings', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/Stop_Drwgs?rs:embed=true' },
  { id: 7, name: 'Delta WF XX', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/Delta_WF_XX?rs:embed=true' },
  { id: 8, name: 'Warning Regarding Plan Paket', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/WarningsRegardingPlanPaket?rs:embed=true' },
  { id: 9, name: 'Warnings Regarding WF Task', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/WarningsRegardingWFTask?rs:embed=true' },
  { id: 10, name: 'Progress per Areas', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/Current_ProgressPerAbschnitt_CurrentProjectWeek?rs:embed=true' },
  
];



export const KPI: Report[] = [
  { id: 1, name: 'Monitoring Current Status', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/Monitoring_Current_Status?rs:embed=true' },
  { id: 2, name: 'Evolution of Packages in Workflow', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/Evolution?rs:embed=true' },
  { id: 3, name: 'Progress in Workflows', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/MonitoringAll_EplassProgress?rs:embed=true' },
  { id: 4, name: 'Weekly Progress Charts', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/WeeklyProgress?rs:embed=true' },
  { id: 5, name: 'Stopped Drawings', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/Stop_Drwgs?rs:embed=true' },
  { id: 6, name: 'Delay Ongoing Drawings', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/DelayOngoingDoc?rs:embed=true' },
  { id: 7, name: 'Workflow Delay Actual vs Plan', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/Delta_WF_XX?rs:embed=true' }
];

export const WARNINGS: Report[] = [
  { id: 1, name: 'Warnings Regarding Plan Paket', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/WarningsRegardingPlanPaket?rs:embed=true' },
  { id: 2, name: 'Warnings Regarding WF Task', url:'https://www.httechus.com/reports/report/Eplass%20Prototype%20Reports/WarningsRegardingWFTask?rs:embed=true' }
];

export const KOBOGEN: Report[] = [
  { id: 1, name: 'Weekly Progress Aushub', url:'https://www.httechus.com/reports/report/Kobogen%20Prototype%20Reports/WeeklyProgressAushub?rs:embed=true' },
  { id: 2, name: 'Weekly Progress Bauer', url:'https://www.httechus.com/reports/report/Kobogen%20Prototype%20Reports/WeeklyProgressBauer?rs:embed=true' },
  { id: 3, name: 'Weekly Progress Building Levels', url:'https://www.httechus.com/reports/report/Kobogen%20Prototype%20Reports/WeeklyProgressBuildingLevels?rs:embed=true' }
];