import { Component, OnInit} from '@angular/core';
import * as d3 from 'd3';
import { _ } from 'underscore';
import {MatSnackBar} from '@angular/material';

import { ProgressEntryServiceService } from '../services/progress-entry-service.service';

import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';

import { MeasurementChange } from '../types/MeasurementChange';
import Swal from 'sweetalert2';

import {MatDialog} from '@angular/material/dialog';

import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { DialogService } from '../services/dialog.service';
import { MaterialModule} from '../material'
import { from } from 'rxjs';
import { BlockingModalComponent } from '../modal/blocking-modal/blocking-modal.component';


@Component({
  selector: 'app-input-progress',
  templateUrl: './input-progress.component.html',
  styleUrls: ['./input-progress.component.css']
})
export class InputProgressComponent implements OnInit {

  constructor(private pEntryService: ProgressEntryServiceService, fb: FormBuilder, snackBar: MatSnackBar,
    public dialogRef: MatDialog, private router: Router, private route: ActivatedRoute, private dialogService: DialogService) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });
  }


  public model: MeasurementChange;

  loading = true;
  disabled= false;
  fragment;
  selectedGroupHeader;
  currentEntryGroupId: number;
  selectedGroupId: number;
  currentTabIndex = 0;
  selected = new FormControl(this.currentTabIndex);
  options: FormGroup;

  // svg handle
  svg;

  //= ELEMENT_DATA;
  xoffset = -200;
  yoffset = 450;

  // Combine all data to scale
  h_output_dim = 300;
  w_output_dim = 400;

  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;

  colors = ['red', 'cyan', 'green', 'blue', 'yellow', 'purple', 'orange', 'brown', 'magenta'];

  project_week;
  measures;
  coordinates;
  globalPathData = {};
  globalScaleData = {};
  entryGroups = [];
  entryGroupsById = {};

  measuresByEntryGroup = {};
  headersByEntryByIndex = {};
  deltasByGroupId = {};

  sectionWeightMatrix = {};
  sectionProgressMatrix = {};

  localDialogRef;

  processProgress() {

    let processData = this.project_week;
    this.localDialogRef = this.dialogRef.open(BlockingModalComponent, {
      disableClose: true
    });

    this.pEntryService.processtEntries(processData).subscribe(res => {
      this.localDialogRef.close();
      Swal(
        'Success!',
        'Form Submitted Sucessfully!',
        'success',
      )
    });
  }


  onSubmit() {
    this.dialogService.openConfirmDialog('Submitting this request will override actual entered progress, are you sure to override the changes?')
      .afterClosed().subscribe(res => {
        if (res) {
          this.localDialogRef = this.dialogRef.open(BlockingModalComponent, {
            disableClose: true
          });
          let postData = [];
          // Write latest updates to changeset
          this.deltasByGroupId[this.selectedGroupId] = this.measures;

          for (var groupId in this.deltasByGroupId) {
            if (!this.deltasByGroupId.hasOwnProperty(groupId)) { continue }

            for (var k in this.deltasByGroupId[groupId]) {
              if (!this.deltasByGroupId[groupId].hasOwnProperty(k)) { continue }

              let secs = this.deltasByGroupId[groupId][k];

              var item1 = secs["items"]
              for (var j in item1) {

                var item2 = item1[j]
                for (var l in item2) {

                  var listItem = item2["items"]
                  for (var li in listItem) {
                    this.model = new MeasurementChange(listItem[li].id, listItem[li].percent_complete);
                    postData.push(this.model);
                  }
                }
              }
            }
          }

          this.pEntryService.postEntries(postData).subscribe(res => {
            this.localDialogRef.close();
            
            Swal(
              'Success!',
              'Form Submitted Sucessfully!',
              'success',
            )
            //(document.getElementById('process') as HTMLButtonElement).disabled = false;
            this.disabled= true;
            
          })
          
        }
      });
    //   this.localDialogRef = this.dialogRef.open(BlockingModalComponent, {
    //     disableClose: true
    //   });
    //   let postData = [];
    //   // Write latest updates to changeset
    //   this.deltasByGroupId[this.selectedGroupId] = this.measures; 

    //   for (var groupId in this.deltasByGroupId) {
    //     if (!this.deltasByGroupId.hasOwnProperty(groupId)) { continue }

    //     for (var k in this.deltasByGroupId[groupId]) {
    //       if (!this.deltasByGroupId[groupId].hasOwnProperty(k)) { continue }

    //       let secs = this.deltasByGroupId[groupId][k];

    //       var item1 = secs["items"]
    //       for (var j in item1) {

    //         var item2 = item1[j]
    //         for (var l in item2) {

    //           var listItem = item2["items"]
    //           for (var li in listItem) {
    //             this.model = new MeasurementChange(listItem[li].id, listItem[li].percent_complete);
    //             postData.push(this.model);
    //           }
    //         }
    //       }
    //     }
    //   }

    //   this.pEntryService.postEntries(postData).subscribe(res => {
    //     this.localDialogRef.close();
    //     Swal(
    //       'Success!',
    //       'Form Submitted Sucessfully!',
    //       'success'
    //     )
    //   })
  }

  tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    let header = this.headersByEntryByIndex[this.selectedGroupId][this.currentTabIndex];
    if (header !== null && header !== undefined) {
      this.draw(this.globalPathData[header], header);
    }
  }

  getSum(items) {
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
      sum += (+items[i].percent_complete / 100.0) * +items[i].item_weight;
    }
    return sum;
  }

  txScale(min, max) {
    return d3.scaleLinear()
      .domain([min, max]) //input
      .range([0, this.w_output_dim]); //output
  }
  // Y scale will fit values from 0-10 within pixels h-0 (Note the inverted domain for the y-scale: bigger is up!)
  tyScale(min, max) {
    return d3.scaleLinear()
      .domain([min, max])
      .range([this.h_output_dim, 0]);
  }

  xScale() {
    return d3.scaleLinear()
      .domain([this.xMin, this.xMax]) //input
      .range([0, this.w_output_dim]); //output
  }
  // Y scale will fit values from 0-10 within pixels h-0 (Note the inverted domain for the y-scale: bigger is up!)
  yScale() {
    return d3.scaleLinear()
      .domain([this.yMin, this.yMax])
      .range([this.h_output_dim, 0]);
  }

  line(points: Array<{ x: number, y: number }>) {
    const tmpFunc = d3.line()
      .x(function (d) {
        return d3.scaleLinear()
          .domain([this.xMin, this.xMax])
          .range([0, this.w_output_dim])(+d[0]);
      }.bind(this))
      .y(function (d) {
        return d3.scaleLinear()
          .domain([this.yMin, this.yMax])
          .range([this.h_output_dim, 0])(d[1]);
      }.bind(this));

    const tarray: Array<[number, number]> = [];
    for (let i = 0; i < points.length; i++) {
      tarray.push([
        points[i].x, points[i].y
      ]);
    }
    return tmpFunc(tarray);
  }

  updateDisplay(major_code: string, minor_code: string, row: any) {
    // TODO: Try to bind this to an event that only fires when the cursor leaves the input box
    //console.log("Before: ", this.sectionProgressMatrix[`${this.selectedGroupHeader}-${major_code}-${minor_code}`][row.mi_id]);
    this.sectionProgressMatrix[`${this.selectedGroupHeader}-${major_code}-${minor_code}`][row.mi_id] = row.percent_complete;
    //console.log("After: ", this.sectionProgressMatrix[`${this.selectedGroupHeader}-${major_code}-${minor_code}`][row.mi_id]);
    // Now still need to trigger a 'repaint' update somehow
    if (!isNaN(this.sectionProgressMatrix[`${this.selectedGroupHeader}-${major_code}-${minor_code}`][row.mi_id])) {
      let header = this.headersByEntryByIndex[this.selectedGroupId][this.currentTabIndex];
      this.draw(this.globalPathData[header], header);
    }
  }

  getSectionProgress(major_code, minor_code) {
    // TODO: Note this data isn't what's being bound to the DOM for user updates, so any entries will not be reflected in
    //       the display
    let sec_sum = 0.0;
    let sec_monitor_items = this.sectionProgressMatrix[`${this.selectedGroupHeader}-${major_code}-${minor_code}`];
    let sec_monitor_item_weights = this.sectionWeightMatrix[`${this.selectedGroupHeader}-${major_code}-${minor_code}`];

    for (let mi_id in sec_monitor_items) {
      if (sec_monitor_items.hasOwnProperty(mi_id)) {
        
        sec_sum += sec_monitor_items[mi_id] * sec_monitor_item_weights[mi_id];
        //if (sec_monitor_item_weights[mi_id] === undefined) {
        //  console.log('weight:  ', sec_monitor_item_weights[mi_id],
        //    'prog: ', sec_monitor_items[mi_id],
        //    'result: ', sec_sum,
        //    'weights list: ', sec_monitor_item_weights,
        //    mi_id, sec_monitor_items
        //  );
        //}
      }
    }
    let ret_val = Math.round(sec_sum * 100) / 100;   
    return ret_val; // Round to nearest 2 decimal places
  }

  addPatterns(colorStripe: string, colorHatch: string) {
 
 
    this.svg.append("defs")
      .append("pattern")
      .attr('id', "diagonal-stripe")
      .attr('width', "8")
      .attr('height', "8")
      .attr('patternUnits', "userSpaceOnUse")
      .attr('patternTransform', "rotate(50)")
      .append("rect")
      .attr({ width: "1.5", height: "50", fill: colorStripe });
 
    this.svg.selectAll("defs")
      .append("pattern")
      .attr('id', "crosshatch")
      .attr('width', "8").attr('height', "8").attr('patternUnits', "userSpaceOnUse").attr('patternTransform', "rotate(45)")
      .append("rect")
      .attr('width', "1.5").attr('height', "50").attr('fill', colorHatch)
      .append("rect")
      .attr('width', "50").attr('height', "1.5").attr('fill', colorHatch);
  }

  getProgressFill(major_code: string, minor_code: string, color: string='blue'): string {
    let tmpProg = this.getSectionProgress(major_code, minor_code);  //Maybe need to truncate this
    if (tmpProg === 0.00) {
      return 'white';
    } else if (tmpProg > 0.00 && tmpProg <= 50.00) {
      return 'url(#crosshatch)';
    } else if (tmpProg > 50.00 && tmpProg < 100.00) {
      return 'url(#diagonal-stripe)';
    } else if (tmpProg === 100.00) {
       return color;
    }
  }

  draw(flat_data, header_code) {
    if (this.globalScaleData[header_code] === null || this.globalScaleData[header_code] === undefined) {
      this.svg.selectAll("svg g#pic").remove();
      //this.svg.selectAll("svg defs").remove();
      return;
    }
    // Set global scale information:
    this.xMin = this.globalScaleData[header_code].xMin;
    this.xMax = this.globalScaleData[header_code].xMax;
    this.yMin = this.globalScaleData[header_code].yMin;
    this.yMax = this.globalScaleData[header_code].yMax;
    // Remove old picture
    this.svg.selectAll("svg g#pic").remove();
    // Redraw new picture
    let new_diag = this.svg.append("g").attr('id', 'pic')

    // Loop over each section, painting each
    let i = 0;
    for (let code in flat_data) {
      if (flat_data.hasOwnProperty(code)) {
        //this.addPatterns(this.colors[i % (this.colors.length - 1)], this.colors[(i + 1) % (this.colors.length - 1)]);
        // TODO: This is the piece you might really get some miles encapsulating into a separate component
        new_diag.append("g")
          .attr('id', 'p' + i)
          .append("path")
          .attr('d', this.line(flat_data[code]))
          .attr('stroke', 'black')
          .attr('stroke-width', 4)
          .attr('fill', this.getProgressFill(header_code, code))
          .attr('opacity', 0.5)
          .on('click', function (d) {
            this.router.navigate(['/inputProgress'], { fragment: `${header_code}-${code}` });
          }.bind(this))
          .on('mouseover', function (d) {
            d3.select(this).attr('opacity', 0.9)
          })
          .on('mouseout', function (d) {
            d3.select(this).attr('opacity', 0.5)
          });

        let lxMin = flat_data[code].reduce(function (min, p) { return p.x < min ? p.x : min; }, flat_data[code][0].x);
        let lxMax = flat_data[code].reduce(function (max, p) { return p.x > max ? p.x : max; }, flat_data[code][0].x);

        let lyMin = flat_data[code].reduce(function (min, p) { return p.y < min ? p.y : min; }, flat_data[code][0].y);
        let lyMax = flat_data[code].reduce(function (max, p) { return p.y > max ? p.y : max; }, flat_data[code][0].y);

        new_diag.select(`g#p${i}`)
          .append("text")
          .attr('x', this.xScale()((lxMax - lxMin)/2.0 + lxMin))
          .attr('y', this.yScale()((lyMax - lyMin) / 2.0 + lyMin))
          .attr('font-family', 'Karla')
          .attr('fill-opacity',0.8)
          .text(code);
        i += 1;
      }
    }
    // Modulo shuffle in case the dimensions are actually the same
    let tmp_list = [];
    for (let i = 1; i < this.colors.length; i++) {
      tmp_list.push(this.colors[i])
    }
    tmp_list.push(this.colors[0]);
    this.colors = tmp_list;
  }

  updateData() {
    this.pEntryService.getAllEntries().subscribe(
      function (data) {
        // Update loading first because as soon as the below bound data gets initialized, it will need to locate
        // the associated DOM elements to update
        this.loading = false;

        this.measuresByEntryGroup = data.measures;
        this.measures = this.measuresByEntryGroup[this.selectedGroupId];

        this.coordinates = data.coordinates;
        this.project_week = data.project_week;
        this.selectedGroupHeader = this.entryGroupsById[this.selectedGroupId].group_code;
        this.globalScaleData = data.scale_data;
        this.globalPathData = data.coordinates;

        // NEW STUFF
        this.sectionWeightMatrix = data.section_weight_matrix;
        this.sectionProgressMatrix = data.section_progress_matrix;
      }.bind(this),
      function (error) {
        console.log(error);
      },
      function (complete) {
        // Need a way to get the tab header by entry and then by tab index in order to map into this.globalPathData
        for (var oKey in this.measuresByEntryGroup) {
          if (this.measuresByEntryGroup.hasOwnProperty(oKey)) {
            this.headersByEntryByIndex[oKey] = {}
            for (let i = 0; i < this.measuresByEntryGroup[oKey].length; i++) {
              this.headersByEntryByIndex[oKey][i] = this.measuresByEntryGroup[oKey][i]['group_header'];
            }
          }
        }
        

        // Needs to be more than the current tab index -- needs to correspond to the tabIndex's header
        let header = this.headersByEntryByIndex[this.selectedGroupId][this.currentTabIndex];
        this.currentEntryGroupId = this.selectedGroupId;
        this.draw(this.globalPathData[header], header);
      }.bind(this));
  }

  toggleData(): void {

    // Set new deltas to the cache (from screen data/state)
    this.deltasByGroupId[this.currentEntryGroupId] = this.measures;

    // Update currentGroupCode to point to the screen we're transitioning to (to screen data/state)
    this.currentEntryGroupId = this.selectedGroupId; // Now Equivalent

    // Update displayed header for the entry group
    this.selectedGroupHeader = this.entryGroupsById[this.currentEntryGroupId].group_code;

    // Update this.measures to reflect existing deltas, or simply the data initially returned from the server
    // (not sure that it will make a difference)
    if (this.deltasByGroupId.hasOwnProperty(this.currentEntryGroupId)) {
      this.measures = this.deltasByGroupId[this.currentEntryGroupId];
    } else {
      this.measures = this.measuresByEntryGroup[this.currentEntryGroupId];
    }
    this.tabChange(0);
  }

  
  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });

    this.svg = d3.select("svg");
    //this.svg.append("defs")
    //  .append("pattern")
    //  .attr({ id: "diagonal-stripe-1", width: "10", height: "10", patternUnits: "userSpaceOnUse" });
    //  , patternTransform: "rotate(60)"
    //})
    //    .append("rect")
    //    .attr({ width: "4", height: "8", transform: "translate(0,0)", fill: "#88AAEE" });


    this.pEntryService.getEntryGroups().subscribe(
      function (data) {
        this.entryGroups = data;
        // Turn your list into an object keyed by the element ids
        this.entryGroupsById = _.object(_.map(data, function (item) {
          return [item['id'], item];
        }));
        this.selectedGroupId = this.entryGroups[0].id;
        this.updateData();
      }.bind(this));
  }


}
