import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-dashboard-kobogen-supervisor',
  templateUrl: './project-dashboard-kobogen-supervisor.component.html',
  styleUrls: ['./project-dashboard-kobogen-supervisor.component.css']
})
export class ProjectDashboardKobogenSupervisorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
