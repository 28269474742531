import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string; 

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
  }
  signIn() {
    this.authService.login(this.username, this.password).subscribe(
      (data) => localStorage.setItem('app-token', data.token),
      (error) => Swal({
        title: 'Wrong Credentials',
        type: 'warning',
        confirmButtonText: 'Try Again'
      }),
      function (complete) {
        Swal({
          toast: true,
          position: 'bottom',
          type: 'success',
          title: 'Login Successful!',
          showConfirmButton: false,
          timer: 3200
        })
        this.router.navigate(['/userDashboard']);
        this.authService.setLoggedIn(true)
      }.bind(this));
  }
}

