import { Component, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { DialogService } from '../../../services/dialog.service';
import { BlockingModalComponent } from '../../../modal/blocking-modal/blocking-modal.component';

import { ProgressEntryServiceService } from '../../../services/progress-entry-service.service';
import { FileUploadService } from '../../../services/file-upload.service'

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.css']
})
export class ProjectDashboardComponent implements OnInit {
  localDialogRef;

  constructor(
    private pEntryService: ProgressEntryServiceService,
     
    public dialogRef: MatDialog, 
    private dialogService: DialogService, 
    public dialog: MatDialog) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(importFileDialog2, {
      width: '350px'
    })
  }

  deleteOldEntries() {
    this.dialogService.openConfirmDialog(
      'You are about to delete the previous week\'s progress entries and performance calculations.  Are you sure you want to proceed ?'
    ).afterClosed().subscribe(res => {
      if (res) {
        this.localDialogRef = this.dialogRef.open(BlockingModalComponent, {
          disableClose: true
        });

        // TODO: Wrap in processing modal
        this.pEntryService.deletePriorEntries().subscribe(d => {
          this.localDialogRef.close();

          Swal(
            'Success!',
            `Removed prior week's entries`, //Everything up through ${d.latest_week-1}
            'success',
          )
        });
      }
    });
  }

  ngOnInit() {
  }

}


@Component({
  selector: 'import-file-dialog-2',
  templateUrl: 'import-file-dialog-2.html',
})
export class importFileDialog2{
  fileToUpload: File = null;
  constructor(
    private fileUploadService : FileUploadService,
    public dialogRef: MatDialogRef<importFileDialog2>
  ){}

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
}

uploadFile() {
  this.fileUploadService.postFile(this.fileToUpload).subscribe(data => {
 console.log('success')
    }, error => {
      console.log(error);
    });
}

}