import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kobogen-report',
  templateUrl: './kobogen-report.component.html',
  styleUrls: ['./kobogen-report.component.css']
})
export class KobogenReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
