



import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UrlSanitizerPipe } from '../../../../../pipes/url-sanitizer.pipe';



import { REPORTS, KPI, WARNINGS, KOBOGEN } from 'src/app/data/mock-report';
import { Report } from 'src/app/model/report';
@Component({
  selector: 'app-progress-report',
  templateUrl: './progress-report.component.html',
  styleUrls: ['./progress-report.component.css']

})
export class ProgressReportComponent implements OnInit {
reports = KOBOGEN;
selectedReport: Report;
  constructor(private router: Router) { }

  ngOnInit() {
  }
 onSelect(report: Report){
   this.selectedReport = report;
 }
}
