import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { UrlSanitizerPipe } from './pipes/url-sanitizer.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule, MatDialog, MatMenuModule } from '@angular/material';
import { InputProgressComponent } from './input-progress/input-progress.component';
import { ProgressEntryServiceService } from './services/progress-entry-service.service';
import { MatDialogModule} from '@angular/material/dialog';
import { ReportDashboardA6Component } from './admin/project-dashboard/project-dashboard-a6/a6-reports2/report-dashboard-a6.component';
import { ProjectDashboardA6Component, importFileDialog } from './admin/project-dashboard/project-dashboard-a6/project-dashboard-a6.component';
import { SsrsReportComponent } from './admin/project-dashboard/project-dashboard-a6/a6-reports1/ssrs-report.component';
import { KobogenReportComponent } from './admin/project-dashboard/project-dashboard-kobogen/kobogen-report/kobogen-report.component';
import { BlockingModalComponent } from './modal/blocking-modal/blocking-modal.component';
import { DRFAuthenticationInterceptor } from './login/interceptors/authentication';
import { ProjectDashboardComponent, importFileDialog2 } from './admin/project-dashboard/project-dashboard-kobogen/project-dashboard.component';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { ProjectDashboardKobogenSupervisorComponent } from './supervisor/project-dashboard-supervisor/project-dashboard-kobogen-supervisor/project-dashboard-kobogen-supervisor.component';
import { SupervisorDashboardComponent } from './supervisor/supervisor-dashboard/supervisor-dashboard.component';
import { ProjectDashboardA6SupervisorComponent } from './supervisor/project-dashboard-supervisor/project-dashboard-a6-supervisor/project-dashboard-a6-supervisor.component';
import { ProjectManagerDashboardComponent } from './projectManager/project-manager-dashboard/project-manager-dashboard.component';
import { ProjectDashboardKobogenPmComponent } from './projectManager/project-dashboard-pm/project-dashboard-kobogen-pm/project-dashboard-kobogen-pm.component';
import { ProjectDashboardA6PmComponent } from './projectManager/project-dashboard-pm/project-dashboard-a6-pm/project-dashboard-a6-pm.component';
import { KpiReportComponent } from './admin/project-dashboard/project-dashboard-a6/kpi/kpi-report/kpi-report.component';
import { MonitoringComponent } from './admin/project-dashboard/project-dashboard-a6/monitoring/monitoring/monitoring.component';
import { WarningsReportComponent } from './admin/project-dashboard/project-dashboard-a6/warnings/warnings-report/warnings-report.component';
import { ChartsReportComponent } from './admin/project-dashboard/project-dashboard-kobogen/charts/charts-report/charts-report.component';
import { ProgressReportComponent } from './admin/project-dashboard/project-dashboard-kobogen/progressMonitoring/progress-report/progress-report.component';
import { MatConfirmDialogComponent } from './modal/mat-confirm-dialog/mat-confirm-dialog.component';
import { CustomFormsModule } from 'ng2-validation';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { ResourceChartsComponent } from './admin/project-dashboard/project-dashboard-kobogen/resource-charts/resource-charts.component';



@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    HeaderComponent,
    ProjectDashboardComponent,
    SsrsReportComponent,
    ProjectDashboardA6Component,
    UrlSanitizerPipe,
    KobogenReportComponent,
    InputProgressComponent,
    BlockingModalComponent,
    importFileDialog,
    importFileDialog2,
    ReportDashboardA6Component,
    ProjectDashboardKobogenSupervisorComponent,
    SupervisorDashboardComponent,
    ProjectDashboardA6SupervisorComponent,
    ProjectManagerDashboardComponent,
    ProjectDashboardKobogenPmComponent,
    ProjectDashboardA6PmComponent,
    KpiReportComponent,
    MonitoringComponent,
    WarningsReportComponent,
    ChartsReportComponent,
    ProgressReportComponent,
    MatConfirmDialogComponent,
    FooterComponent,
    ResourceChartsComponent
  

   

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatToolbarModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatMenuModule,
    CustomFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: DRFAuthenticationInterceptor,
    multi: true,
  },
   {provide: LocationStrategy, useClass: HashLocationStrategy},
  MatDialog,
  ProgressEntryServiceService,
  
],
entryComponents: [BlockingModalComponent, MatConfirmDialogComponent, importFileDialog, importFileDialog2],
  bootstrap: [AppComponent ]
})
export class AppModule { }
