import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard }  from '../app/login/auth.guard';
import { InputProgressComponent } from './input-progress/input-progress.component';
import { UserDashboardComponent } from './admin/user-dashboard/user-dashboard.component';
import { ProjectDashboardComponent } from './admin/project-dashboard/project-dashboard-kobogen/project-dashboard.component';
import { SsrsReportComponent } from './admin/project-dashboard/project-dashboard-a6/a6-reports1/ssrs-report.component';
import { ProjectDashboardA6Component } from './admin/project-dashboard/project-dashboard-a6/project-dashboard-a6.component';
import { KobogenReportComponent } from './admin/project-dashboard/project-dashboard-kobogen/kobogen-report/kobogen-report.component';
import { ReportDashboardA6Component } from './admin/project-dashboard/project-dashboard-a6/a6-reports2/report-dashboard-a6.component';
import { SupervisorDashboardComponent } from './supervisor/supervisor-dashboard/supervisor-dashboard.component';
import { ProjectDashboardKobogenSupervisorComponent } from './supervisor/project-dashboard-supervisor/project-dashboard-kobogen-supervisor/project-dashboard-kobogen-supervisor.component';
import { ProjectDashboardA6SupervisorComponent } from './supervisor/project-dashboard-supervisor/project-dashboard-a6-supervisor/project-dashboard-a6-supervisor.component';
import { ProjectManagerDashboardComponent } from './projectManager/project-manager-dashboard/project-manager-dashboard.component';
import { ProjectDashboardA6PmComponent } from './projectManager/project-dashboard-pm/project-dashboard-a6-pm/project-dashboard-a6-pm.component';
import { ProjectDashboardKobogenPmComponent } from './projectManager/project-dashboard-pm/project-dashboard-kobogen-pm/project-dashboard-kobogen-pm.component';
import { MonitoringComponent } from './admin/project-dashboard/project-dashboard-a6/monitoring/monitoring/monitoring.component';
import { KpiReportComponent } from './admin/project-dashboard/project-dashboard-a6/kpi/kpi-report/kpi-report.component';
import { WarningsReportComponent } from './admin/project-dashboard/project-dashboard-a6/warnings/warnings-report/warnings-report.component';
import { ProgressReportComponent } from './admin/project-dashboard/project-dashboard-kobogen/progressMonitoring/progress-report/progress-report.component';
import { ChartsReportComponent } from './admin/project-dashboard/project-dashboard-kobogen/charts/charts-report/charts-report.component';
import { ResourceChartsComponent } from './admin/project-dashboard/project-dashboard-kobogen/resource-charts/resource-charts.component';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full' },
  {path: 'login', component:LoginComponent, pathMatch: 'full'},
  {path: 'userDashboard', component:UserDashboardComponent, canActivate: [AuthGuard]},
  {path: 'projectDashboard', component:ProjectDashboardComponent, canActivate: [AuthGuard]},
  {path: 'ssrs', component:SsrsReportComponent, canActivate: [AuthGuard]},
  {path: 'ssrsa6', component:ProjectDashboardA6Component, canActivate: [AuthGuard]},
  {path: 'ssrskbg', component:KobogenReportComponent, canActivate: [AuthGuard]},
  {path: 'inputProgress', component:InputProgressComponent, canActivate: [AuthGuard]},
  {path: 'reporta6', component:ReportDashboardA6Component, canActivate: [AuthGuard]},


  //canActivate: [AuthGuard]
  //supervisor
  {path: 'supervisorDashboard', component: SupervisorDashboardComponent, canActivate: [AuthGuard]},
  {path: 'kbgsupervisor', component:ProjectDashboardKobogenSupervisorComponent , canActivate: [AuthGuard]},
  {path: 'a6supervisor', component:ProjectDashboardA6SupervisorComponent , canActivate: [AuthGuard]},

  //projectManager
  {path: 'pmDashboard', component: ProjectManagerDashboardComponent, canActivate: [AuthGuard]},
  {path: 'kbgpm', component:ProjectDashboardKobogenPmComponent, canActivate: [AuthGuard]},
  {path: 'a6pm', component:ProjectDashboardA6PmComponent, canActivate: [AuthGuard]},

  //A6 reports
  {path: 'kpi', component:KpiReportComponent, canActivate: [AuthGuard]},
  {path: 'monitoring', component:MonitoringComponent, canActivate: [AuthGuard]},
  {path: 'warnings', component:WarningsReportComponent, canActivate: [AuthGuard]},

  //kbg reports
  {path: 'charts', component:ChartsReportComponent, canActivate: [AuthGuard]},
  {path: 'monitoringkbg', component:ProgressReportComponent, canActivate: [AuthGuard]},
  //resources Charts
  {path: 'resources', component:ResourceChartsComponent, canActivate: [AuthGuard]},
  


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 220] // [x, y]
  })],   
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
export const routingComponents = [LoginComponent, UserDashboardComponent, ProjectDashboardComponent]
